
















































































































import { defineComponent, onMounted, onUnmounted, PropType, reactive, ref, watch } from "@vue/composition-api";
import analytics from "@/services/analytics";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "CourseSession",
  props: {
    session: {
      type: Object as PropType<Learnlink.CourseSession.default>,
      required: true
    },
    course: {
      type: Object as PropType<Learnlink.Course.default>,
      required: true
    },
    activeSession: {
      type: Number as PropType<number>,
      required: true
    }
  },
  setup(props, { emit }) {
    const vm = reactive({
      tab: 0
    });
    const openingTime = ref("");
    const countingDown = ref(false);
    let interval: number;
    const update = () => {
      if (props.session.open) {
        const updateInterval = () => {
          const now = new Date();
          const open = new Date(props.session.open?.date + " " + props.session.open?.time);
          const diff = open.getTime() - now.getTime();

          if (diff > 0) {
            const hours = Math.floor(diff / (1000 * 60 * 60));
            const minutes = Math.floor((diff / (1000 * 60)) % 60);
            const seconds = Math.floor((diff / 1000) % 60);

            if (hours >= 24) {
              // format to 1.december
              const formattedDate = open.toLocaleDateString("no-NO", { day: "numeric", month: "long" });
              openingTime.value = `Åpnes ${formattedDate}`;
            }
            else {
              openingTime.value = `Åpner om ${hours}t ${minutes}m ${seconds}s`;
            }
            countingDown.value = true;
          }
          else {
            countingDown.value = false;
            openingTime.value = "...";
            if (interval) {
              clearInterval(interval);
              emit("update-session");
            }
          }
        };

        interval = setInterval(updateInterval, 1000);
        updateInterval();
      }
      else {
        countingDown.value = false;
        openingTime.value = "";
        if (interval) {
          clearInterval(interval);
        }
      }
    };

    update();
    watch(props.session, () => update());

    onUnmounted(() => {
      if (interval) {
        clearInterval(interval);
      }
    });
    onMounted(() => {
      analytics.track("openCourseSession", {
        courseID: props.session.courseID,
        title: props.session.title
      });
    });

    return {
      vm,
      openingTime,
      countingDown
    };
  }
});
