












































import { userModule } from "@/store/modules/user";
import { computed, defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";
import { getCourse, listCourseSessions } from "@/api/courseService";
import { useNotifier } from "@/providers/notifier";
import CourseSession from "./CourseSession.vue";
import handleError from "@/helpers/errors";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "CourseContent",
  components: { CourseSession },
  props: {
    courseID: {
      type: String as PropType<string>,
      required: true,
    },
    sessionsLeft: {
      type: Number as PropType<number>,
      default: 0,
    },
  },
  setup(props) {
    const vm = reactive({
      course: {} as Learnlink.Course.default,
      courseSessions: [] as Learnlink.CourseSession.default[],
      loading: true,
      panel: 0,
    });

    const notify = useNotifier();

    const updateInfo = async () => {
      try {
        [vm.course, vm.courseSessions] = await Promise.all([
          getCourse(props.courseID),
          listCourseSessions(props.courseID),
        ]);
      }
      catch (e) {
        handleError(e);
        notify({
          title: "Klarte ikke å laste kursinnhold 🤯",
          message: "Kontakt support hvis problemet vedvarer.",
          type: "error",
        });
      }
      vm.loading = false;
    };

    onMounted(updateInfo);

    const isCalendarCourse = computed(() => vm.course.location === "calendar");
    const activeSession = computed(() => userModule.state.user.role === "seller" ? 16 : vm.course.sessions - props.sessionsLeft);

    return {
      activeSession,
      isCalendarCourse,
      vm,
      updateInfo
    };
  },
});
